<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="所属的部门" prop="did">
        <el-select
          style="width: 100%"
          v-model="dataForm.did"
          placeholder="请选择部门"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组名字" prop="name">
        <el-input v-model="dataForm.name" placeholder="班组名字"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      departmentOptions: [], // 部门列表
      dataForm: {
        id: 0,
        did: "",
        name: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        did: [
          { required: true, message: "所属的部门id不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "班组名字不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取部门列表
    getDepartment() {
      this.departmentOptions = [];
      this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
        if (res && res.code === 0) {
          if (res.data.list.length > 0) {
            res.data.list.forEach((item) => {
              this.departmentOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    init(id) {
      this.getDepartment();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.team.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.team.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.team.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
